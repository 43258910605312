<template>
  <div class="standard">
    <va-tabs v-model="selectedTab" @click:prev="prevClicked">
      <template #tabs :hide-slider="true">
        <va-tab>金融航运类标准 </va-tab>
        <va-tab>非金融航运类标准 </va-tab>
      </template>
    </va-tabs>
    <div class="standard-content" ref="standard1" v-show="selectedTab == 0">
      <p class="display-3 title">金融航运类入库标准</p>
      <p class="sub-title">一、外资资管类</p>
      <p>1、境外金融机构在境内设立独资或控股的从事资产管理相关业务的企业（私募、QFLP、QDLP、不良资产处置公司及咨询公司等）且符合下列两个条件之一：</p>
      <ul class="number-with-quato">
        <li>（1）境外母公司或集团同一控制下的境外机构已获得证监会QFII/RQFII业务资格</li>
        <li>（2）境外集团全球资管规模排名前200（或全球资管规模达到300亿美元）；或各国资管规模排名前20名；或资产管理各细分领域（如证券、债券、股权、另类资产、不良资产处置等）全球前20名</li>
      </ul>
      <p>2、资产管理专业服务机构各细分领域全球排名前20（托管销售、份额登记、估值交易、合规风控等）在境内设立从事基金第三方服务的专业机构</p>
      <p class="sub-title">二、银行类</p>
      <p>1、 银行法人行；银行一级子公司；外资银行分行等</p>
      <p>2、非银金融机构：如理财公司、消费金融、财务公司、信托公司、货币经纪、汽车金融等。</p>
      <p>3、商业银行设立的银行专营机构：资金营运中心等。</p>
      <p class="sub-title">三、保险类</p>
      <p>1、保险公司及一级分子公司；</p>
      <p>2、 保险中介机构（保险代理、保险经纪、保险公估等），满足以下条件之一的：</p>
      <ul class="number-with-quato">
        <li>（1）股东是保险公司、国企央企、上市公司等；</li>
        <li>（2）注册资本10亿以上；</li>
        <li>（3）预计营收1亿以上。</li>
      </ul>
      <p class="sub-title">四、证券类</p>
      <p>1、保险公司及一级分子公司；</p>
      <p>2、证券、期货公司拟设一级子公司；</p>
      <p>3、证券、期货公司机构分公司，满足以下条件之一，即视作为重大项目：</p>
      <ul class="number-with-quato">
        <li>（1）母公司注册资本超10亿元人民币；</li>
        <li>（2）评级在A类以上；</li>
        <li>（3）预计税收超1000万元人民币；</li>
        <li>（4）母公司营收超1亿元人民币。</li>
      </ul>
      <p class="sub-title">五、公募类</p>
      <p>1、公募基金总部；</p>
      <p>2、公募基金专业子公司；</p>
      <p>3、公募基金分公司，预计税收超1000万元人民币，即视作为重大项目；</p>
      <p>4、独立基金销售公司。</p>
      <p class="sub-title">六、投资类</p>
      <p>1、私募证券机构：历史管理规模超过50亿元的业内知名公募基金经理新设的私募证券机构。</p>
      <p>2、私募股权机构：国内在管基金规模排名前20的股权投资机构新设的管理人和基金；总规模预计100亿元以上且首期募集20亿元以上的产业基金。</p>
      <p>3、央企、国企下设以自有基金投资的投资平台。</p>
      <p class="sub-title">七、航运</p>
      <p>航运类企业，经营范围里面包含船舶运输、船舶管理、船代货代、仓储物流、航运经济、海事和航运咨询，等投资方为央企或者省级地方国企，且注册资本1000万以上。</p>
      <p class="sub-title">八、融资租赁与商业保理</p>
      <p>投资方为央企或者省市级地方国企，融资租赁公司注册资本2亿元以上，商业保理公司注册资本1亿元以上。</p>
      <p class="sub-title">九、金融科技</p>
      <p>1、金融监管部门、金融市场和基础设施、持牌金融机构等下设金融科技子公司（股权穿透后控股50%）；</p>
      <p>2、境外知名金融机构设立的金融科技子公司；</p>
      <p>3、国内500强企业旗下金融科技子公司；</p>
      <p>4、被纳入中国金融科技50强榜单的企业；</p>
      <p>5、金融科技领域独角兽企业。</p>
      <p class="sub-title">十、其他</p>
      <p>其他一行两会或者金融局批准的机构，如第三方支付、信用评级等机构。</p>
    </div>
    <div class="standard-content" ref="standard2" v-show="selectedTab == 1">
      <p class="display-3 title">非金融航运类入库标准</p>
      <p class="sub-title">一、经济规模</p>
      <p>1. 合同外资1000万美元以上项目（新设或增资）；</p>
      <p>2. 内资注册资本亿元以上项目（新设或增资）；</p>
      <p>3. 预估税收千万元以上项目；</p>
      <p>4. 营收亿元以上项目；</p>
      <p>注册资本或营收10亿元以上，合同外资1亿美元以上，预估税收1亿元以上作为特别重大项目。</p>
      <p class="sub-title">二、投资方</p>
      <p>1. 投资方是世界500强；</p>
      <p>2. 投资方是央企、地方国企；</p>
      <p>3. 投资方是民营企业500强；</p>
      <p>4. 投资方是上市公司（包括科创板）；</p>
      <p class="sub-title">三、行业地位</p>
      <p>行业细分领域头部机构。如：</p>
      <p>1. 专业服务业细分领域行业排名前20项目等；</p>
      <p>2. 商贸业知名品牌等；</p>
      <p>3. “四新”经济项目、高新技术企业、独角兽企业等；</p>
      <p class="sub-title">四、其他</p>
      <p>1. 跨国公司地区总部（增能项目）；</p>
      <p>2. 国际组织等功能性机构；</p>
      <p>3. 扩大开放和制度创新标志性项目。</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@font-face {
  font-family: HuawenZongSongMin;
  src: url('../../assets/font/HuawenZongSong.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: FangSongMin;
  src: url('../../assets/font/FangSong.ttf');
  font-weight: normal;
  font-style: normal;
}

.standard {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  background-color: #fff;
  padding: 0.5rem 0.5rem;
  padding-top: 3rem;
  font-size: 1.1rem;
  line-height: 2;
  @media (min-width: 992px) {
    padding: 0.5rem 2rem;
  }

  .standard-content {
    flex: 1;
    font-family: FangSongMin;
  }

  .sub-title {
    font-weight: 700;
    margin-top: 0.5rem;
  }
  .title {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: HuawenZongSongMin;

    @media (min-width: 992px) {
      padding-bottom: 3rem;
      font-size: 2rem;
    }
  }

  ul {
    padding-left: 0.2rem;
    list-style: none;
    @media (min-width: 992px) {
      padding-left: 2rem;
    }
  }
}
</style>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const selectedTab = ref(0)

    return {
      selectedTab,
      prevClicked: function() {
        alert(1)
      }
    }
  }
})
</script>
