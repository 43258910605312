
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const selectedTab = ref(0)

    return {
      selectedTab,
      prevClicked: function() {
        alert(1)
      }
    }
  }
})
